import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { enableMapSet, setAutoFreeze } from "immer";

import HyperFlowHomeGlobalStyling from "@mirinae/apps/home-app/modules/defines/HyperFlowHomeGlobalStyling";
import HyperFlowHomeRoutes from "./hyperFlowHomeRoutes";

// immer global settings
enableMapSet();
setAutoFreeze(false);

const HyperFlowHomeApp = () => {
    return (
        <RouterWrapper>
            <HyperFlowHomeGlobalStyling />
            <HyperFlowHomeRoutes />
        </RouterWrapper>
    );
};

const RouterWrapper = styled.div`
    height: 100%;
`;

export default HyperFlowHomeApp;
