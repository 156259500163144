import { createGlobalStyle } from "styled-components/macro";

// global-styling shared by all the apps
const GlobalStyling = createGlobalStyle`

    html {
        min-height: 100vh;
    }

    body {
        height: -webkit-fill-available;
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: 100%;
        min-height: 100vh;
        margin: 0;

        font-family: DM Sans, serif;
        font-size: 13px;
        font-weight: 500;
        color: #1a2c38; // #2d4656;
        background-color: #fff; // #d7dddb;
        
        #root {
            height: 100vh;
        }

        .panel-background {
            background-color: #eeedea;
        }

        .nav-hover {
            background-color: #e6e7e7;
        }

        .box-border {
            border-color: #c8cbcb;
        }

        .disabled-label {
            color: #b4babd;
        }
        
        .hyperflow-search {
            --ant-color-bg-container: #fff;
            --ant-color-border: #bbcfe2;
        }
    }
`;

export default GlobalStyling;
