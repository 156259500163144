import en from "./main.json";
import ko from "./main-ko.json";
import ja from "./main-ja.json";

// export { en };
// export { ko };
// export { ja };
// export { "en-US": en };

export default { en, ko, ja, "en-US": en };
