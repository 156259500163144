import React, { Suspense } from "react";
import GlobalStyling from "@mirinae/defines/GlobalStyling";

const Base = props => {
    return (
        <>
            <GlobalStyling />
            <Suspense fallback={<div>loading...</div>}>{props.children}</Suspense>
        </>
    );
};

export default Base;
