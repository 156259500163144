// Ant.d global theme, used in main index.js
export const HyperFlowHomeGlobalTheme = {
    cssVar: true,
    token: {
        colorPrimary: "#1677FF", // "#0084ff", // "#6041FB", // Primary color
        fontSize: 14, // Global font size
        fontFamily: "Noto Sans KR, Montserrat, sans serif",
        colorText: "#1a2c38", // Global text color
        colorBgBase: "#fff", // '#f0f2f5', // Background color
    },
    components: {
        Menu: {
            inlineIndent: 16,
            itemSelectedColor: "#0084ff",
            itemSelectedBg: "#edf7ff",
        },
        Form: {
            itemMarginBottom: 12,
            verticalLabelPadding: 4,
        },
    },
};
