import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { localPaths } from "@home-app/modules/defines/paths";

const HyperFlowIntro = lazy(() => import(/* webpackChunkName: "HyperFlowIntro" */ "@home-app/pages/HyperFlowIntro"));
const HyperFlowHome = lazy(() => import(/* webpackChunkName: "HyperFlowHome" */ "@home-app/pages/HyperFlowHome"));
const HyperFlowPrices = lazy(() => import(/* webpackChunkName: "HyperFlowPrices" */ "@home-app/pages/HyperFlowPrices"));
const HyperFlowDocs = lazy(() => import(/* webpackChunkName: "HyperFlowDocs" */ "@home-app/pages/HyperFlowDocs"));
const HyperFlowHelp = lazy(() => import(/* webpackChunkName: "HyperFlowHelp" */ "@home-app/pages/HyperFlowHelp"));

const HyperFlowSendChatbotEnquiry = lazy(
    () => import(/* webpackChunkName: "HyperFlowSendChatbotEnquiry" */ "@home-app/pages/HyperFlowSendChatbotEnquiry")
);
const HyperFlowSendWaitList = lazy(() => import(/* webpackChunkName: "HyperFlowSendWaitList" */ "@home-app/pages/HyperFlowSendWaitList"));

const hyperFlowHomeRoutes = () => {
    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route path={localPaths.intro} element={<HyperFlowIntro />} />
                <Route path={localPaths.home} element={<HyperFlowHome />} />
                <Route path={localPaths.prices} element={<HyperFlowPrices />} />
                <Route path={localPaths.docs} element={<HyperFlowDocs />} />
                <Route path={localPaths.help} element={<HyperFlowHelp />} />
                <Route path={localPaths.addChatbotEnquiry} element={<HyperFlowSendChatbotEnquiry />} />
                <Route path={localPaths.joinWaitlist} element={<HyperFlowSendWaitList />} />

                {/*<Route path="*" element={<ExternalRedirector to="/404.html" reload={true}/>} />*/}
                <Route path="*" element={<HyperFlowIntro />} />
            </Routes>
        </Suspense>
    );
};

export default hyperFlowHomeRoutes;
