import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";

import "@fontsource-variable/montserrat";
import "@fontsource-variable/noto-sans-kr";
import "@fontsource/roboto";

import AppBase from "@mirinae/apps/shared/components/appBase";
import HyperFlowHomeApp from "./HyperFlowHomeApp";
import { HyperFlowHomeGlobalTheme } from "@home-app/modules/defines/styles";

import { i18next } from "@mirinae/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

// nuke useless 50 line deprecation warnings!
const originalConsoleError = console.error;
console.error = (...args) => {
    if (typeof args[0] === "string" && args[0].includes("is deprecated")) {
        originalConsoleError(args[0]); // Log only the warning message
        return;
    }
    originalConsoleError(...args); // Log other errors/warnings normally
};

root.render(
    <ConfigProvider theme={HyperFlowHomeGlobalTheme}>
        <React.StrictMode>
            <CookiesProvider>
                <GoogleOAuthProvider clientId="88024450945-ivs1idd5sd8p1mv4501h7jtpvrr2aga9.apps.googleusercontent.com">
                    <BrowserRouter>
                        <I18nextProvider i18n={i18next}>
                            <AppBase>
                                <Routes>
                                    {/* need this to properly resolve paths when mounting app subpaths */}
                                    <Route path="home-app/*" element={<HyperFlowHomeApp />} />
                                    <Route path="*" element={<HyperFlowHomeApp />} />
                                </Routes>
                            </AppBase>
                        </I18nextProvider>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </CookiesProvider>
        </React.StrictMode>
    </ConfigProvider>
);
